import React from "react"

import Hero from "../sections/hero"
import Section from "../sections/section"
import Card from "../blocks/card"
import Map from "../blocks/map"

const CollectionTemplate = props => {
  // React.useEffect(() => {
  //   console.log("=== Location Page ===", props)
  // })

  const page = props.pageContext?.page
  // console.log("=== Collection Page data ===", page)

  const defaultLabels = props.pageContext?.defaultLabels
  const defaultPages = props.pageContext?.defaultPages

  const ItemSection = props => (
    <React.Fragment>
      {props.items?.length > 0 && (
        <Section {...props} titleColor="PRIMARY">
          {props.items.slice(0, 6).map(item => (
            <Card
              key={item.id}
              {...item}
              width="SIZE_50"
              center={false}
              titleColor="PRIMARY"
              subtitleColor="SECONDARY"
              background="LIGHT"
              padding={true}
              url={props.collectionPath + "/" + item.slug}
              type={props.type}
              linkLabel={
                props.type && defaultLabels[props.type.toLowerCase() + "Link"]
              }
              imagePosition="LEFT"
              imageWidth="SIZE_50"
              imageCaption={item.image?.caption}
              imageFluid={item.image?.file?.childImageSharp?.fluid}
              imageUrl={
                item.image &&
                // process.env.GATSBY_STRAPI_HOST +
                item.image.formats?.thumbnail?.url
              }
            />
          ))}
        </Section>
      )}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Section padding={false}>
        <Hero
          {...page}
          imageFluid={page.image?.file?.childImageSharp?.fluid}
          imageCaption={page.image?.caption}
          imageUrl={
            // process.env.GATSBY_STRAPI_HOST +
            page.image?.formats?.thumbnail?.url
          }
          background={"DARK"}
          height={"SIZE_50"}
        />
      </Section>

      {page.__typename === "Strapi_Location" && (
        <Section title="Localisation" titleColor="PRIMARY">
          <Map
            locations={[page]}
            displayFilter={false}
            defaultPages={defaultPages}
            height="SIZE_50"
          />
        </Section>
      )}

      {page.locations && (
        <Section title="Localisation" titleColor="PRIMARY">
          <Map
            locations={page.locations}
            displayFilter={false}
            defaultPages={defaultPages}
            height="SIZE_50"
          />
        </Section>
      )}

      <ItemSection
        title="Animations"
        type="EVENT"
        items={page.events}
        collectionPath={
          (defaultPages?.eventsPage?.paths &&
            defaultPages?.eventsPage?.paths[0].slug) ||
          "/events"
        }
      />
      <ItemSection
        title="Sites protégés"
        type="LOCATION"
        items={page.locations}
        collectionPath={
          (defaultPages?.locationsPage?.paths &&
            defaultPages?.locationsPage?.paths[0].slug) ||
          "/locations"
        }
      />
      <ItemSection
        title="Conservateur"
        type="LOCATION"
        items={page.curatorLocations}
        collectionPath={
          (defaultPages?.locationsPage?.paths &&
            defaultPages?.locationsPage?.paths[0].slug) ||
          "/locations"
        }
      />
      <ItemSection
        title="Partenaire"
        type="LOCATION"
        items={page.partnerLocations}
        collectionPath={
          (defaultPages?.locationsPage?.paths &&
            defaultPages?.locationsPage?.paths[0].slug) ||
          "/locations"
        }
      />
      <ItemSection
        title="Gestionnaire"
        type="LOCATION"
        items={page.managerLocations}
        collectionPath={
          (defaultPages?.locationsPage?.paths &&
            defaultPages?.locationsPage?.paths[0].slug) ||
          "/locations"
        }
      />
      <ItemSection
        title="Propriétaire"
        type="LOCATION"
        items={page.ownerLocations}
        collectionPath={
          (defaultPages?.locationsPage?.paths &&
            defaultPages?.locationsPage?.paths[0].slug) ||
          "/locations"
        }
      />
      <ItemSection
        title="Documents"
        type="DOCUMENT"
        items={page.documents}
        collectionPath={
          (defaultPages?.documentsPage?.paths &&
            defaultPages?.documentsPage?.paths[0].slug) ||
          "/documents"
        }
      />
      <ItemSection
        title="Types de milieu"
        type="ENVIRONMENT"
        items={page.environments}
        collectionPath={
          (defaultPages?.environmentsPage?.paths &&
            defaultPages?.environmentsPage?.paths[0].slug) ||
          "/environments"
        }
      />
      <ItemSection
        title="Espèces"
        type="TAXON"
        items={page.taxons}
        linkLabel={defaultLabels.taxonsPage}
        collectionPath={
          (defaultPages?.taxonsPage?.paths &&
            defaultPages?.taxonsPage?.paths[0].slug) ||
          "/taxons"
        }
      />
      <ItemSection
        title="Conservateurs"
        type="ENTITY"
        items={page.curators}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Partenaires"
        type="ENTITY"
        items={page.partners}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Gestionnaires"
        type="ENTITY"
        items={page.managers}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Propriétaires"
        type="ENTITY"
        items={page.owners}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Animateurs"
        type="ENTITY"
        items={page.organizers}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Structures"
        type="ENTITY"
        items={page.parentEntities}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Membres"
        type="ENTITY"
        items={page.childEntities}
        collectionPath={
          (defaultPages?.entitiesPage?.paths &&
            defaultPages?.entitiesPage?.paths[0].slug) ||
          "/entities"
        }
      />
      <ItemSection
        title="Communes"
        type="MUNICIPALITY"
        items={page.municipalities}
        collectionPath={
          (defaultPages?.municipalitiesPage?.paths &&
            defaultPages?.municipalitiesPage?.paths[0].slug) ||
          "/municipalities"
        }
      />
      <ItemSection
        title="Programmes"
        type="PROGRAM"
        items={page.programs}
        collectionPath={
          (defaultPages?.programsPage?.paths &&
            defaultPages?.programsPage?.paths[0].slug) ||
          "/programs"
        }
      />
    </React.Fragment>
  )
}

export default CollectionTemplate
